import React, { useCallback, useMemo, useState } from "react";
import usePagination from "./usePagination";
import SchoolReviewSelect from "./SchoolReviewSelect";
import { columns, gradeMapping } from "./constants";
import { minDate, normalizeValue } from "./utils";
import { useStudentsForReviewQuery, useSchoolReviewMutation } from "./query";
import { Button, Table } from "@Ignite-Reading/ui-kit/components";
import { toast } from "react-toastify";

const SchoolReviewEdit = () => {
  const { fundingSources, programTerms, schoolSeats, submittedStudents } =
    useStudentsForReviewQuery().data || {};
  const [studentProgramEnrollments, setStudentProgramEnrollments] = useState({});
  const queryMutation = useSchoolReviewMutation({ studentProgramEnrollments });

  const isStartDatePopulated = (studentId) => {
    const startDateValue = studentStartDate(studentId);
    return startDateValue && startDateValue.trim() !== "";
  };

  const studentProgramEnrollmentValue = useCallback(
    (studentId, fieldName) => {
      return studentProgramEnrollments?.[studentId]?.[fieldName];
    },
    [studentProgramEnrollments]
  );

  const setStudentProgramEnrollmentValue = useCallback((studentId, fieldName, value) => {
    setStudentProgramEnrollments((prevStudentProgramEnrollments) => ({
      ...prevStudentProgramEnrollments,
      [studentId]: {
        ...prevStudentProgramEnrollments[studentId],
        [fieldName]: value,
      },
    }));
  }, []);

  const removeStudentProgramEnrollmentValues = useCallback((studentIds) => {
    setStudentProgramEnrollments((prevStudentProgramEnrollments) => {
      const newStudentProgramEnrollments = { ...prevStudentProgramEnrollments };
      studentIds.forEach((studentId) => delete newStudentProgramEnrollments[studentId]);
      return newStudentProgramEnrollments;
    });
  }, []);

  const programTermStartDate = useCallback(
    (programTermId) => {
      return programTerms.find((programTerm) => programTerm.id === programTermId)?.start_date;
    },
    [programTerms]
  );

  const studentStartDate = useCallback(
    (studentId) => {
      const currentStartDate = studentProgramEnrollmentValue(studentId, "startDate");
      if (currentStartDate) {
        return currentStartDate;
      }

      const programTermId = studentProgramEnrollmentValue(studentId, "programTerm");
      if (programTermId) {
        const startDate = minDate(programTermStartDate(programTermId));
        setStudentProgramEnrollmentValue(studentId, "startDate", startDate);
        return startDate;
      }

      return "";
    },
    [programTermStartDate, setStudentProgramEnrollmentValue, studentProgramEnrollmentValue]
  );

  const submit = useCallback(() => {
    let valid = true;
    Object.keys(studentProgramEnrollments).forEach((studentId) => {
      if (!("programTerm" in studentProgramEnrollments[studentId])) {
        toast.error(`Please select a Program Term for Student ${studentId}.`);
        valid = false;
      }
    });
    if (!valid) {
      return;
    }
    const toastId = toast.loading("Submitting students for program enrollment...");
    queryMutation.mutate(null, {
      onSuccess: ({ data }) => {
        toast.update(toastId, {
          render: "Student program enrollments submitted.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        removeStudentProgramEnrollmentValues(data);
      },
      onError: ({ response }) => {
        toast.update(toastId, {
          render: "We weren't able to enroll some students.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        removeStudentProgramEnrollmentValues(response.data);
      },
    });
  }, [queryMutation, removeStudentProgramEnrollmentValues, studentProgramEnrollments]);

  const data = useMemo(
    () =>
      submittedStudents?.map((student) => ({
        name: student.last_name + ",  " + student.first_name,
        id: student.roster_integration_external_id,
        grade: gradeMapping[student.grade_level],
        email: student.email,
        dateOfBirth: normalizeValue(student.date_of_birth),
        hispanicOrLatinoDescent: normalizeValue(student.hispanic_or_latino_descent),
        race: normalizeValue(student.race),
        englishLanguageLearner: normalizeValue(student.english_language_learner),
        languageSpokenAtHome: normalizeValue(student.language_spoken_at_home),
        teacherName:
          normalizeValue(student.teacher_last_name) +
          "  " +
          normalizeValue(student.teacher_first_name),
        teacherEmail: normalizeValue(student.teacher_email),
        lunchProgram: normalizeValue(student.lunch_program),
        specialEducationProgram: normalizeValue(student.special_education_program),
        specialEducationProgramAccommodations: normalizeValue(
          student.special_education_program_accommodations
        ),
        tutoringTimeSlot: schoolSeats[student.school_seat_id],
        programTerms: (
          <SchoolReviewSelect
            name="programTerm"
            onSelect={setStudentProgramEnrollmentValue}
            options={programTerms}
            studentId={student.id}
            value={studentProgramEnrollmentValue(student.id, "programTerm")}
          />
        ),
        fundingSources: (
          <SchoolReviewSelect
            name="fundingSource"
            onSelect={setStudentProgramEnrollmentValue}
            options={fundingSources}
            studentId={student.id}
            value={studentProgramEnrollmentValue(student.id, "fundingSource")}
          />
        ),
        programStartDate: (
          <input
            aria-label={`Student ${student.id} Start Date`}
            data-testid={student.id + "-startDate"}
            id={student.id + "-startDate"}
            type="date"
            className={`-ml-[1px] py-1.5 px-3 leading-none focus:ring-brand-500 focus:border-brand-500 shadow-sm text-sm border-zinc-300 rounded-md ${
              isStartDatePopulated(student.id) ? "bg-white" : "bg-fuchsia-50"
            }`}
            value={studentStartDate(student.id)}
            onChange={(e) => {
              setStudentProgramEnrollmentValue(student.id, "startDate", e.target.value);
            }}
            min={minDate(
              programTermStartDate(studentProgramEnrollmentValue(student.id, "programTerm"))
            )}
          />
        ),
      })),
    [
      submittedStudents,
      schoolSeats,
      setStudentProgramEnrollmentValue,
      programTerms,
      studentProgramEnrollmentValue,
      fundingSources,
      studentStartDate,
      programTermStartDate,
    ]
  );

  const { currentData, onChangePage, pages, currentPage } = usePagination({
    data,
  });

  return (
    <div className="py-6">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Review Student Information</h1>
          <p className="mt-2 text-sm text-gray-700">Review and Edit Student Information.</p>
        </div>
      </div>
      <div className="mt-6 pt-6 flex items-center"></div>
      <div className="table-container overflow-x-auto relative divide-gray-600">
        <Table
          data-testid="school-review-table"
          cellSpacing="default"
          columns={columns}
          data={currentData}
          pagination={{
            "aria-label": "School Review Pagination",
            count: pages,
            onChange: onChangePage,
            page: currentPage,
          }}
          defaultSortKey="name"
          noDataMessage="You did it! All students have been reviewed."
        >
          {(data) =>
            data.map((row) => (
              <Table.Row key={row.id}>
                <Table.Cell className="sticky left-[1px] z-10 sm:pl-0 px-0 py-0">
                  <div className="absolute top-[-1px] left-[-1px] right-[-1px] bottom-[-1px] flex items-center h-[calc(100%+2px)] w-[calc(100%+2px)] sm:pl-6 px-4 py-4 border border-gray-300 bg-white">
                    {row.name}
                  </div>
                </Table.Cell>
                <Table.Cell className="sticky left-[185px] z-40 shadow-[inset_-1px_0_0_0_#d1d5db] bg-white">
                  {row.grade}
                </Table.Cell>
                <Table.Cell className="border border-gray-300">{row.programTerms}</Table.Cell>
                <Table.Cell className="border border-gray-300">{row.programStartDate}</Table.Cell>
                <Table.Cell className="border border-gray-300">{row.fundingSources}</Table.Cell>
                <Table.Cell className="border border-gray-300">{row.tutoringTimeSlot}</Table.Cell>
                <Table.Cell className="border border-gray-300">{row.email}</Table.Cell>
                <Table.Cell className="border border-gray-300">{row.dateOfBirth}</Table.Cell>
                <Table.Cell className="border border-gray-300">
                  {row.hispanicOrLatinoDescent}
                </Table.Cell>
                <Table.Cell className="border border-gray-300">{row.race}</Table.Cell>
                <Table.Cell className="border border-gray-300">
                  {row.languageSpokenAtHome}
                </Table.Cell>
                <Table.Cell className="border border-gray-300">{row.teacherName}</Table.Cell>
                <Table.Cell className="border border-gray-300">{row.teacherEmail}</Table.Cell>
                <Table.Cell className="border border-gray-300">{row.id}</Table.Cell>
                <Table.Cell className="border border-gray-300">{row.lunchProgram}</Table.Cell>
                <Table.Cell className="border border-gray-300">
                  {row.specialEducationProgram}
                </Table.Cell>
                <Table.Cell className="border border-gray-300">
                  {row.specialEducationProgramAccommodations}
                </Table.Cell>
              </Table.Row>
            ))
          }
        </Table>
      </div>
      <div className="pt-6 space-x-5 text-sm text-zinc-500 flex items-center justify-end">
        <p>Clicking Submit will create the program enrollment and initiate the pairing process.</p>
        <Button
          disabled={Object.keys(studentProgramEnrollments).length === 0}
          onClick={submit}
          className="button-primary text-base"
          data-testid="submit-button"
        >
          <p className="px-5">Submit</p>
        </Button>
      </div>
    </div>
  );
};

export default SchoolReviewEdit;
